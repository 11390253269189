import { createQueryString } from '.'

const redirectUrl = `${import.meta.env.VITE_TC_DASHBOARD_URL}/create/`
const clientId = import.meta.env.VITE_TC_SHOPIFY_CLIENT_ID
const shopifyAPIVersion = import.meta.env.VITE_TC_SHOPIFY_API_VERSION

const scopes = [
    'read_products',
    'write_products',
    'read_customers',
    'write_customers',
    'read_orders',
    'read_all_orders',
    'write_orders',
    'read_script_tags',
    'read_analytics',
    'read_checkouts',
    'write_script_tags',
    'unauthenticated_read_collection_listings',
    'unauthenticated_read_product_listings',
    'write_product_listings',
    'unauthenticated_write_checkouts',
    'unauthenticated_write_customers',
    'write_mobile_payments',
    'read_mobile_payments',
    'unauthenticated_read_product_tags',
    'unauthenticated_read_selling_plans',
    'write_mobile_platform_applications',
    'read_discounts',
    'write_discounts',
    'read_inventory',
    'read_themes',
    'read_cart_transforms',
    'write_cart_transforms',
]

const createGid = (id, type) => {
    if (typeof id === 'string' && id.startsWith('gid://shopify/')) {
        return id
    }
    return `gid://shopify/${type}/${id}`
}

const decodeGid = (id) => {
    let decodedId = id

    try {
        decodedId = window.atob(id)
    } catch (error) {
        if (import.meta.env.DEV) console.log(error)
    }

    if (typeof id === 'string' && decodedId.includes('gid://shopify/')) {
        return Number(decodedId.split('/').pop())
    }
    return decodedId
}

export const decodeIfBase64 = (id) => {
    let decodedId = id

    // value is already in the format we're looking for if it is a number
    if (typeof id === 'number') {
        return id
    }

    try {
        decodedId = window.atob(id)
    } catch (error) {
        if (import.meta.env.DEV) console.log(error)
    }

    if (typeof id === 'string' && decodedId.includes('gid://shopify/')) {
        return Number(decodedId.split('/').pop())
    }
    return decodedId
}

export const getIdFromGid = (gid) => {
    if (typeof gid === 'string' && gid.includes('gid://shopify/')) {
        return Number(gid.split('/').pop())
    }
    if (typeof gid === 'number') {
        return gid
    }
    if (typeof gid === 'string' && !Number.isNaN(gid)) {
        return Number(gid)
    }
    return decodeIfBase64(gid)
}

const extractDomain = (url) => {
    let domain
    domain = url.includes('://') ? url.split('/')[2] : url.split('/')[0]
    domain = domain.split(':')[0]
    return domain
}

const shopifyStoreParseName = (input) => {
    if (input.includes('.myshopify.com')) {
        return extractDomain(input)
    }
    if (!input.includes('.myshopify.com') && !input.includes('.')) {
        return `${extractDomain(input)}.myshopify.com`
    }
    return extractDomain(input)
}

const randomNonce = () =>
    Math.random().toString(36).slice(2, 15) +
    Math.random().toString(36).slice(2, 15)

const getNonce = (query) => {
    if (query) {
        try {
            return btoa(query)
        } catch (error) {
            console.error(error)
            return btoa(randomNonce())
        }
    }
    return btoa(randomNonce())
}

const decodeHost = (host) => {
    if (host) {
        return atob(host)
    }
    return null
}

const getConnectLink = ({
    host,
    storeUrl,
    siteQuery,
    customRedirectPath = '',
}) => {
    let permissionLink = ''
    // if-else is more readable here
    // eslint-disable-next-line unicorn/prefer-ternary
    // if (host) permissionLink = `${decodeHost(host)}/oauth/authorize?`
    // else
    permissionLink = `${shopifyStoreParseName(storeUrl)}/admin/oauth/authorize?`

    const shopifyQuery = {
        client_id: clientId,
        scope: scopes.join(','),
        redirect_uri: customRedirectPath
            ? `${import.meta.env.VITE_TC_DASHBOARD_URL}/${customRedirectPath}/`
            : redirectUrl,
        state: getNonce(createQueryString(siteQuery)),
    }

    return `https://${permissionLink}${createQueryString(shopifyQuery)}`
}

const verifyShopifyInstallStatus = (domain, accessToken) => {
    const graphqlUrl = `https://${domain}.myshopify.com/api/${shopifyAPIVersion}/graphql.json`

    const request = new Request(graphqlUrl, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/graphql',
            'X-Shopify-Storefront-Access-Token': accessToken,
        }),
        body: `{shop{name}}`,
    })
    return fetch(request)
}

const redirectToShopifyAuth = (
    shop,
    siteQuery = null,
    customRedirectPath = null
) => {
    const connectLink = getConnectLink({
        storeUrl: shop,
        siteQuery,
        customRedirectPath,
    })

    // Let the user see 1 second of the toast, then redirect
    setTimeout(() => {
        window.location.href = connectLink
    }, 1000)
}

const connectStore = (shopifyStore, redirectPath) => {
    window.top.location.href = getConnectLink({
        storeUrl: shopifyStore,
        siteQuery: null,
        customRedirectPath: redirectPath,
    })
}

const installShopifyApp = (params) => {
    const query = {
        utm_source: 'shopify-app-store',
        mkto_utm_campaign__c: 'shopify-app-store',
        mkto_utm_content__c: 'shopify-app-store-content',
        mkto_utm_medium__c: 'shopify-app-store-medium',
        mkto_utm_source__c: 'shopify-app-store-source',
        mkto_utm_term__c: 'shopify-app-store-terms',
    }

    redirectToShopifyAuth(params.shop, query)
}

export {
    createGid,
    decodeGid,
    getConnectLink,
    getNonce,
    verifyShopifyInstallStatus,
    redirectToShopifyAuth,
    connectStore,
    installShopifyApp,
}
