import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { typedRequest } from 'store/_utils/api-fetch'
import getHeaders from 'store/_utils/get-headers'
import { PhoenixBlockTemplate } from './types/blocks'

const baseAPIUrl = import.meta.env.VITE_TC_BACKEND_API as string
const blockTemplatesUrl = `${baseAPIUrl}/v1/app-studio/block-bank`

type AppStudioBlockTemplatesState = {
    blockBank: CategorizedBlockTemplates[]
    blockTemplates: PhoenixBlockTemplate[]
    loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
    error?: string
    initialized: boolean
}

const initialState: AppStudioBlockTemplatesState = {
    blockBank: [],
    blockTemplates: [],
    loading: 'idle',
    initialized: false,
}

export type CategorizedBlockTemplates = {
    key: string
    label: string
    blockTemplates: PhoenixBlockTemplate[]
}
type FetchBlockBankResponse = {
    categorizedBlocks: CategorizedBlockTemplates[]
}
export const fetchBlockBank = createAsyncThunk<
    // Return type of the payload creator
    CategorizedBlockTemplates[],
    // First argument to the payload creator
    undefined,
    // Types for ThunkAPI
    { state: RootState }
>('app-studio-block-templates/fetchBlockBank', async (_, { getState }) => {
    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<FetchBlockBankResponse>(
        `${blockTemplatesUrl}/${appId}`,
        {
            method: 'GET',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        }
    )

    if (!response || !response.categorizedBlocks?.length) {
        throw new Error('Failed to fetch block bank')
    }

    const decodedCategorizedBlocks: CategorizedBlockTemplates[] =
        response.categorizedBlocks.map((category) => {
            const decodedBlockTemplates = category.blockTemplates.map(
                (block) => {
                    return {
                        ...block,
                        code: atob(block.code),
                        // Transpiled code exists here as well but is not needed yet in the
                        // dashboard as it's transpiled on the fly in the editor.
                        // We can add it if needed
                        // transpiledCode: atob(block.transpiledCode),
                    }
                }
            )

            return {
                ...category,
                blockTemplates: decodedBlockTemplates,
            }
        })

    return decodedCategorizedBlocks
})

const appStudioBlockTemplatesSlice = createSlice({
    name: 'app-studio-block-templates',
    initialState,
    reducers: {
        initializeBlockBank(state) {
            state.initialized = true
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBlockBank.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchBlockBank.fulfilled, (state, action) => {
            state.loading = 'fulfilled'
            state.blockBank = action.payload
            state.initialized = true
            addBlockTemplatesToList(state, action.payload)
        })
        builder.addCase(fetchBlockBank.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
            state.initialized = true
        })
    },
})

const addBlockTemplatesToList = (
    state: AppStudioBlockTemplatesState,
    categorizedTemplates: CategorizedBlockTemplates[]
) => {
    // Flatten categories to a list
    const blockTemplatesFlatList = categorizedTemplates.reduce(
        (acc, category) => {
            return acc.concat(category.blockTemplates)
        },
        [] as PhoenixBlockTemplate[]
    )

    // Filter blockTemplates for duplicates within itself
    const uniqueBlockTemplates: PhoenixBlockTemplate[] = []
    blockTemplatesFlatList.forEach((block) => {
        // Check if the block is unique within itself, find all instances of the block
        const selfInstances = uniqueBlockTemplates.filter(
            (selfBlock) => selfBlock._id === block._id
        )

        let latestBlock: PhoenixBlockTemplate

        // If there is only one instance of the block, it is unique
        if (!selfInstances.length) {
            latestBlock = block
        } else {
            // If there are multiple instances of the block, get the latest updatedAt block
            latestBlock = selfInstances.reduce((latest, current) => {
                return latest.updatedAt > current.updatedAt ? latest : current
            })
        }

        // Check if the block is unique within the state, find all instances of the block in the state
        const stateInstances = state.blockTemplates.filter(
            (stateBlock) => stateBlock._id === block._id
        )

        // If there are no instances of the block in the state, it is unique
        if (stateInstances.length === 0) {
            uniqueBlockTemplates.push(latestBlock)
            return
        }

        // If there are instances of the block in the state, get the latest updatedAt block
        const latestStateBlock = stateInstances.reduce((latest, current) => {
            return latest.updatedAt > current.updatedAt ? latest : current
        })

        // If the block is newer than the latest block in the state, remove the old block and add the new block
        if (latestBlock.updatedAt > latestStateBlock.updatedAt) {
            state.blockTemplates = state.blockTemplates.filter(
                (stateBlock) => stateBlock._id !== block._id
            )
            uniqueBlockTemplates.push(latestBlock)
        } else {
            // If the block is older than the latest block in the state, do not add the block
            return
        }
    })

    // Update the blockTemplates in the state
    state.blockTemplates = state.blockTemplates.concat(uniqueBlockTemplates)
}

export const selectBlockTemplates = (
    state: RootState
): PhoenixBlockTemplate[] => {
    return state.appStudioBlockTemplate.blockTemplates
}

export const selectBlockBank = (
    state: RootState
): CategorizedBlockTemplates[] => {
    return state.appStudioBlockTemplate.blockBank
}
export default appStudioBlockTemplatesSlice.reducer
