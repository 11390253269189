import {
    configureStore,
    combineReducers,
    ThunkAction,
    Action,
    applyMiddleware,
} from '@reduxjs/toolkit'
import appConfigReducer from 'store/AppConfig/app-config-slice'
import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux'
import customBlocksReducer from './CustomBlocks/custom-blocks-slice'
import workspaceReducer from './CustomBlocks/workspace-reducer'
import app from './App/app-slice'
import mobileDownloadBannerSlice from './App/mobile-download-banner-slice'
import integrations from './Integrations/integrations-slice'
import designPages from './Settings/design-pages-slice'
import settings from 'store/Settings/settings-slice'
import dynamicProduct from './Settings/dynamic-product-slice'
import userIntegrations from './Settings/user-integration-slice'
import captureKit from './CaptureKit'
import captureKitWidget from './CaptureKit/widget-slice'
import qrImageSlice from './CaptureKit/qr-image-slice'
import billingLink from './BillingLinks'
import billingQuote from './BillingQuotes/billing-quotes-slice'
import tapcartMerchant from './TapcartMerchant/tapcart-merchant-slice'
import notifications from './Notifications/notifications-slice'
import drops from './Drops/drops-slice'
import platformsReducer from './Platforms/platforms-slice'
import alertModalReducer from './AlertModal/alert-modal-slice'
import badges from './Badges'
import integrationDetailsReducer from './integrationDetails/integration-details-slice'
import userReducer from './User/user-slice'
import assetsSlice from './Assets/assets-slice'
import chargesSlice from './Charges'
import screensReducer from './Screens/screens-slice'
import automatedPushSlice from './Notifications/AutomatedPush/automated-push-slice'
import aiSlice from './AI/ai-slice'
import themesReducer from './Themes/themes-slice'
import chargeeBeeCustomerSlice from './Customers'
import webbridgeScriptSliceReducer from './Screens/webbridge-script-slice'
import modalsSlice from './Modals/modals-slice'
import invoicesSlice from './Invoices/invoices-slice'
import subscriptionSlice from './Subscription/subscription-slice'
import mobileInstallBannerSlice from './MobileInstallBanner/mobile-install-banner-slice'
import integrationBlocksSliceReducer from './IntegrationBlocks/slice'
import phoenixLayoutSlice from './Phoenix/layouts-slice'
import appStudioBlockTemplateSlice from './Phoenix/block-templates'
import allIntegrations from './Integrations/all-integrations'

const reducers = combineReducers({
    ai: aiSlice,
    alertModal: alertModalReducer,
    allIntegrations,
    app,
    appConfig: appConfigReducer,
    appStudioBlockTemplate: appStudioBlockTemplateSlice,
    assets: assetsSlice,
    automatedPush: automatedPushSlice,
    badges,
    billingLink,
    billingQuote,
    captureKit,
    captureKitWidget,
    charges: chargesSlice,
    customBlocks: customBlocksReducer,
    customBlocksWorkspace: workspaceReducer,
    customers: chargeeBeeCustomerSlice,
    designPages,
    drops,
    dynamicProduct,
    integrationBlocks: integrationBlocksSliceReducer,
    integrationDetailsReducer,
    integrations,
    invoices: invoicesSlice,
    mobileDownloadBanner: mobileDownloadBannerSlice,
    mobileInstallBanner: mobileInstallBannerSlice, // This is Mobile Download Banner v2
    modals: modalsSlice,
    notifications,
    phoenixLayouts: phoenixLayoutSlice,
    platforms: platformsReducer,
    qrImageSlice,
    screens: screensReducer,
    settings,
    subscription: subscriptionSlice,
    tapcartMerchant,
    themes: themesReducer,
    user: userReducer,
    userIntegrations,
    webbridgeScript: webbridgeScriptSliceReducer,
})

const asyncFunctionMiddleware =
    (storeAPI: any) => (next: any) => (action: any) => {
        if (typeof action === 'function') {
            return action(storeAPI.dispatch, storeAPI.getState)
        }

        return next(action)
    }

const middlewareEnhancer = applyMiddleware(asyncFunctionMiddleware)

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware(
            {
                serializableCheck: {
                    // Ignore these field paths in all actions
                    ignoredActionPaths: [
                        'payload.entities.multiBlockSections',
                        'meta.arg.body',
                        'meta.arg.qrCode',
                        'payload.meta.arg.qrCode',
                        'register',
                        'rehydrate',
                        'payload',
                        'payload.subscription',
                        'meta.arg.scheduledDate',
                        'meta.arg',
                    ],
                    // Ignore these paths in the state
                    ignoredPaths: [
                        'designPages',
                        'captureKit.data.meta.arg.qrCode',
                        'app.data.subscription',
                        'app.data.createdAt',
                        'subscription',
                        'billingLink',
                        'customBlocksWorkspace.preview',
                        'app.data.createdAt',
                        'app',
                        'notifications.data.scheduledNotifications',
                        'notifications.data.pastNotifications',
                        'settings.data.blocks',
                        'customBlocks.entities',
                        'drops.data.drop',
                        'settings.data.multiBlockSections',
                        'settings.data.pdpBlocks.0.blocks',
                        'user.data.createdAt',
                        'platforms.data.ios.version',
                        'platforms.data.android.version',
                        'platforms.data',
                        'integrationBlocks.entities',
                        'modals.saveChangesModal.saveFunc',
                        'modals.saveChangesModal.discardFunc',
                    ],
                },
            },
            [middlewareEnhancer]
        ),
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
const useAppDispatch = () => useDispatch<typeof store.dispatch>()