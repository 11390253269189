import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Provider } from '@shopify/app-bridge-react'

function AppBridgeProvider({ children }) {
    const location = useLocation()

    // The host may be present initially, but later removed by navigation.
    // By caching this in state, we ensure that the host is never lost.
    // During the lifecycle of an app, these values should never be updated anyway.
    // Using state in this way is preferable to useMemo.
    // See: https://stackoverflow.com/questions/60482318/version-of-usememo-for-caching-a-value-that-will-never-change
    const [appBridgeConfig] = useState(() => {
        const host =
            new URLSearchParams(location.search).get('host') ||
            window.__SHOPIFY_DEV_HOST

        window.__SHOPIFY_DEV_HOST = host

        return {
            host,
            apiKey: import.meta.env.VITE_TC_SHOPIFY_CLIENT_ID,
            forceRedirect: false,
        }
    })

    if (!import.meta.env.VITE_TC_SHOPIFY_CLIENT_ID || !appBridgeConfig.host) {
        return <>{children}</>
    }
    return <Provider config={appBridgeConfig}>{children}</Provider>
}

export default AppBridgeProvider
