import { errorToast } from 'components/common/Toasts'
import FontName from 'fontname'
import parseFontAttributes from 'pages/BrandKit/components/BrandKitTypography/utils/parse-font-attributes'
import { FontMeta } from 'types/dashboard-types'

export type Font = {
    fontFamily: string
    file: File | Blob | string
    postScriptName?: string
    fontSubfamily?: string
    fullName?: string
    fontWeight?: number
    fontStyle?: 'normal' | 'italic'
}

export const readFileMetadata = (fontFile: Blob | File | string) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
            try {
                const fontMeta = FontName.parse(
                    event?.target?.result
                )[0] as FontMeta
                resolve({
                    fontFamily: fontMeta.fontFamily,
                    fontSubfamily: fontMeta.fontSubfamily,
                    postScriptName: fontMeta.postScriptName,
                    fullName: fontMeta.fullName,
                })
            } catch (error) {
                reject(error)
            }
        }
        reader.readAsArrayBuffer(fontFile)
    })
}
function base64ToBufferAsync(base64) {
    const dataUrl = base64
    return fetch(dataUrl).then((res) => {
        return res.arrayBuffer()
    })
}

export const weightMap = {
    100: 'Thin (100)',
    200: 'Extra Light (200)',
    300: 'Light (300)',
    400: 'Regular (400)',
    500: 'Medium (500)',
    600: 'Semi Bold (600)',
    700: 'Bold (700)',
    800: 'Extra Bold (800)',
    900: 'Black (900)',
}

const getBase64FromUrl = async (url) => {
    const data = await fetch(url)
    const blob = await data.blob()
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            const base64data = reader.result
            resolve(base64data)
        }
    })
}

export async function loadFontInitialFont({ fontFamily, url, fullName }) {
    if (!url) return
    const buffer = await getBase64FromUrl(url).then((res) => {
        return base64ToBufferAsync(res)
    })

    const fontFace = new FontFace(fullName, buffer)
    await fontFace.load().then((loadedFont) => {
        document.fonts.add(loadedFont)
    })

    return { fontFace }
}

export const groupOptions = (options) => {
    const groupedOptions = []

    options.map((fontOption) => {
        const groupedFontIndex = groupedOptions?.findIndex((groupedFont) => {
            return groupedFont?.fontFamily === fontOption.fontFamily
        })

        // if font family already exists in groupedOptions, add the font style to the grouped font family config
        if (groupedFontIndex >= 0) {
            // if font style is regular or medium move to first position in styles array
            if (
                fontOption.fullName.toLowerCase().includes('regular') ||
                fontOption.fullName.toLowerCase().includes('medium')
            ) {
                groupedOptions[groupedFontIndex] = {
                    ...groupedOptions[groupedFontIndex],
                    styles: [
                        ...groupedOptions[groupedFontIndex].styles,
                        {
                            fullName: fontOption.fullName,
                            fontFamily: fontOption.fontFamily,
                            fontFace: fontOption.fontFace,
                            fontSubfamily: fontOption.fontSubfamily,
                            postScriptName: fontOption.postScriptName,
                            file: fontOption.file,
                            url: fontOption.url,
                            fontAssetID: fontOption.fontAssetID,
                            fontWeight: fontOption.fontWeight,
                            fontStyle: fontOption.fontStyle,
                            fileExtension:
                                fontOption.fileType ?? fontOption.fileExtension,
                        },
                    ],
                }
                return
            }
            // if font style is not regular or medium append to end of styles array
            groupedOptions[groupedFontIndex] = {
                ...groupedOptions[groupedFontIndex],
                styles: [
                    ...groupedOptions[groupedFontIndex].styles,
                    {
                        fullName: fontOption.fullName,
                        fontFamily: fontOption.fontFamily,
                        fontFace: fontOption.fontFace,
                        fontSubfamily: fontOption.fontSubfamily,
                        postScriptName: fontOption.postScriptName,
                        file: fontOption.file,
                        url: fontOption.url,
                        fontAssetID: fontOption.fontAssetID,
                        fontWeight: fontOption.fontWeight,
                        fontStyle: fontOption.fontStyle,
                        fileExtension:
                            fontOption.fileType ?? fontOption.fileExtension,
                    },
                ],
            }
            return
        }
        // if font family doesnt exist in groupedOptions, push new font family to groupedOptionsArray with styles property and font style
        groupedOptions.push({
            fontFamily: fontOption.fontFamily,
            styles: [
                {
                    fullName: fontOption.fullName,
                    fontFamily: fontOption.fontFamily,
                    fontFace: fontOption.fontFace,
                    fontSubfamily: fontOption.fontSubfamily,
                    postScriptName: fontOption.postScriptName,
                    file: fontOption.file,
                    fontAssetID: fontOption.fontAssetID,
                    fontWeight: fontOption.fontWeight,
                    fontStyle: fontOption.fontStyle,
                    url: fontOption.url,
                    fileExtension:
                        fontOption.fileType ?? fontOption.fileExtension,
                },
            ],
        })
    })

    return groupedOptions
}

export async function loadFontInitialFonts({ fonts }) {
    const fontOptions = []
    fonts.map(async (font) => {
        if (!font.url) return

        const attributes = parseFontAttributes(font)
        fontOptions.push({
            fullName: font.fullName,
            fontFamily: font.fontFamily,
            fontFace: font.fontFace,
            fontSubfamily: font.fontSubfamily,
            postScriptName: font.postScriptName,
            file: font.file,
            fontAssetID: font.fontAssetID,
            url: font.url,
            fileExtension: font.fileExtension,
            fontWeight: font?.fontWeight ?? attributes?.weight ?? 400,
            fontStyle: font?.fontStyle ?? attributes?.style ?? 'normal',
        })

        const buffer = await getBase64FromUrl(font.url).then((res) => {
            return base64ToBufferAsync(res)
        })

        const fontFace = new FontFace(font.fullName, buffer)
        await fontFace.load().then((loadedFont) => {
            document.fonts.add(loadedFont)
        })
    })

    const groupedFontOptions = groupOptions(fontOptions)
    return groupedFontOptions
}

export async function loadFont(
    {
        fontFamily,
        file,
        fullName,
        postScriptName,
        fontSubfamily,
        fileType,
    }: Font,
    fonts: Font[]
) {
    const currentFontIndex =
        (fonts &&
            fonts.findIndex(
                (font) =>
                    font.fontFamily === fontFamily &&
                    font.postScriptName === postScriptName
            )) ||
        0

    if (currentFontIndex < 0) {
        const fontFace = new FontFace(
            fullName,
            typeof file === 'string' ? `url(${file})` : await file.arrayBuffer()
        )
        try {
            await fontFace.load().then((loadedFont) => {
                document.fonts.add(loadedFont)
            })

            const parsedFont = await readFileMetadata(file)
            return { fontFace, parsedFont: { ...parsedFont, fileType } }
        } catch (err) {
            return errorToast(`105 Font ${fontFamily} failed to load`)
        }
    }

    return {
        fontFace: file,
        parsedFont: {
            fontFamily,
            fontSubfamily,
            postScriptName,
            fullName,
        },
    }
}

export const parseFamily = (fontFamilyStr) => {
    let updatedFontFamily = ''
    if (fontFamilyStr.includes('-')) {
        updatedFontFamily = fontFamilyStr.split('-')[0]
    } else if (fontFamilyStr.includes('_')) {
        updatedFontFamily = fontFamilyStr.split('_')[0]
    } else {
        updatedFontFamily = fontFamilyStr.split(' ')[0]
    }
    return updatedFontFamily
}

export const onFontUploaded = (newFont, arr) => {
    const uploadedFontsCopy = [...arr]

    const fontFamilyIndex = uploadedFontsCopy.findIndex(
        (font) => font.fontFamily === newFont.fontFamily
    )
    if (fontFamilyIndex >= 0) {
        uploadedFontsCopy[fontFamilyIndex].styles.push({
            fullName: newFont.fullName,
            fontFamily: newFont.fontFamily,
            fontFace: newFont.fontFace,
            fontSubfamily: newFont.fontSubfamily,
            postScriptName: newFont.postScriptName,
            file: newFont.file,
            url: newFont.url,
            fileExtension: newFont.fileExtension,
            fontWeight: newFont.fontWeight,
            fontStyle: newFont.fontStyle,
        })
    } else {
        uploadedFontsCopy.push({
            fontFamily: newFont.fontFamily,
            styles: [
                {
                    fullName: newFont.fullName,
                    fontFamily: newFont.fontFamily,
                    fontFace: newFont.fontFace,
                    fontSubfamily: newFont.fontSubfamily,
                    postScriptName: newFont.postScriptName,
                    file: newFont.file,
                    url: newFont.url,
                    fileExtension: newFont.fileExtension,
                    fontWeight: newFont.fontWeight,
                    fontStyle: newFont.fontStyle,
                },
            ],
        })
    }

    return uploadedFontsCopy
}
