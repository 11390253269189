import {
    createEntityAdapter,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit'
import { fetchSettings } from './settings-slice' // userIntegrations are pulled off the Settings obj
import {
    selectIntegrationEntities,
    selectIntegrationNames,
} from 'store/Integrations/integrations-slice'

const userIntegrationsAdapter = createEntityAdapter({ selectId: (i) => i.name })

export const slice = createSlice({
    name: 'userIntegrations',
    initialState: userIntegrationsAdapter.getInitialState(),
    reducers: {
        enableIntegration: (state) => ({
            ...state,
            enabled: true,
        }),
        disconnectIntegration: (state) => ({
            ...state,
            enabled: false,
        }),
    },
    extraReducers: {
        [fetchSettings.fulfilled]: (state, action) => {
            userIntegrationsAdapter.setAll(
                state,
                action.payload.entities.userIntegrations || []
            )
        },
    },
})

const { reducer, actions } = slice
export default reducer

export const { enableIntegration, disconnectIntegration } = actions

export const {
    selectById: selectUserIntegrationById,
    selectIds: selectUserIntegrationIds,
    selectEntities: selectUserIntegrationEntities,
    selectAll: selectAllUserIntegrations,
    selectTotal: selectTotalUserIntegrations,
} = userIntegrationsAdapter.getSelectors((state) => state.userIntegrations)

export const selectActiveUserIntegrationsObj = (state) => {
    const { userIntegrations } = state
    return Object.values(userIntegrations.entities).filter(
        (integration) => integration.enabled
    )
}

export const selectInactiveUserIntegrations = (state) => {
    const { userIntegrations } = state
    return userIntegrations.ids.filter((integrationName) => {
        const { name, enabled } = userIntegrations.entities[integrationName]

        return integrationName === name && !enabled
    })
}

export const selectActiveUserIntegrationsObjWithoutEnterprise = (state) => {
    const { userIntegrations, integrations } = state
    return userIntegrations.ids.reduce((acc, integration) => {
        const { name, enabled } = userIntegrations.entities[integration]
        if (!integrations.entities[integration]?.premium)
            return { ...acc, [name]: enabled }
        return acc
    }, {})
}

export const selectActiveUserIntegrationsEnterpriseArray = (state) => {
    const { userIntegrations, integrations } = state
    return userIntegrations.ids.filter((integration) => {
        const { enabled } = userIntegrations.entities[integration]
        return enabled && integrations.entities[integration]?.premium
    })
}

export const selectActiveUserIntegrationsStandardArray = (state) => {
    const { userIntegrations, integrations } = state
    return userIntegrations.ids.filter((integration) => {
        const { enabled } = userIntegrations.entities[integration]
        return !integrations.entities[integration]?.premium && enabled
    })
}

export const selectAllActiveUserIntegrationsArray = (state) => {
    const { userIntegrations } = state
    return userIntegrations.ids.filter((integration) => {
        const { enabled } = userIntegrations.entities[integration]
        return enabled
    })
}

export const selectAllActiveUserIntegrationsObj = (state) => {
    const { userIntegrations, allIntegrations } = state
    const active = userIntegrations.ids.reduce((obj, curr) => {
        const { enabled } = userIntegrations.entities[curr]

        if (enabled) {
            obj = {
                ...obj,
                [allIntegrations.entities?.[curr]?.id]:
                    userIntegrations.entities[curr],
            }
        }
        return { ...obj }
    }, {})
    return active
}

export const selectAllInactiveUserIntegrationsObj = createSelector(
    [
        selectIntegrationEntities,
        selectUserIntegrationEntities,
        selectIntegrationNames,
    ],
    (integrations, userIntegrations, integrationNames) => {
        const inactive = {}

        integrationNames.forEach((integrationId) => {
            const userIntegration = userIntegrations[integrationId]
            if (!userIntegration || !userIntegration.enabled) {
                const integration = integrations[integrationId]
                if (integration) {
                    inactive[integration.id] = integration
                }
            }
        })

        return inactive
    }
)

export const selectTotalActive = (state) => {
    const { userIntegrations } = state
    const activeIntegrations = userIntegrations.ids.filter((integration) => {
        const { enabled } = userIntegrations.entities[integration]
        return enabled
    })
    return activeIntegrations.length
}

export const selectTypesOfActiveIntegrations = (state) => {
    const integrationTypes = {
        favorites: [
            'tapcart-wishlist',
            'tapcart-wishlistv2',
            'simplistic-wishlist',
            'simplistic-wishlist-v2',
            'swym',
        ],
        shoppableInstagram: ['shoppable-instagram', 'yotpo-ugc'],
        subscription: ['recharge'],
        reviews: ['yotpo', 'trustspot', 'stamped', 'okendo'],
        productRecommendations: ['nosto'],
        productRecommendationsGrid: ['nosto'],
        loyalty: ['smile-enterprise', 'swell', 'loyalty-lion'],
        contactSharing: ['talkable-v2'],
    }

    const integrationsOn = {
        favorites: null,
        shoppableInstagram: null,
        reviews: null,
        productRecommendations: null,
        productRecommendationsGrid: null,
        subscription: null,
        loyalty: null,
        contactSharing: null,
    }
    const userIntegrations = selectActiveUserIntegrationsObj(state)

    userIntegrations.map((integration) => {
        if (integration.enabled) {
            Object.keys(integrationTypes).map((integrationType) => {
                if (
                    integrationTypes[integrationType].includes(integration.name)
                ) {
                    integrationsOn[integrationType] = integration.name
                }
            })
        }
    })

    return integrationsOn
}
