import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { RootState } from 'store'
import { typedRequest } from 'store/_utils/api-fetch'
import getHeaders from 'store/_utils/get-headers'
import { LayoutType, PhoenixLayout } from './types/layouts'
import { FetchLayoutResponse, FetchLayoutsResponse } from './api-types'
import {
    BlockUpdatePayload,
    PhoenixBlock,
    PhoenixBlockTemplate,
} from './types/blocks'

const baseAPIUrl = import.meta.env.VITE_TC_BACKEND_API as string
const phoenixAPI = `${baseAPIUrl}/phoenix`
const appStudioAPI = `${baseAPIUrl}/v1/app-studio` // phoenix naming will be deprecated

type PhoenixLayoutState = {
    fetchedLayouts: PhoenixLayout[]
    currentSelectedLayout: PhoenixLayout | null
    loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
    error?: string
    initialized: boolean
}

const initialState: PhoenixLayoutState = {
    fetchedLayouts: [],
    currentSelectedLayout: null,
    loading: 'idle',
    error: undefined,
    initialized: false,
}

export const fetchDraftLayoutByType = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout | undefined,
    // First argument to the payload creator
    LayoutType,
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/fetchDraftLayoutByType', async (layoutType, { getState }) => {
    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<FetchLayoutsResponse>(
        `${phoenixAPI}/layouts/${appId}?type=${layoutType}&limit=1&sort=desc&readonly=false`,
        {
            method: 'GET',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        }
    )

    if (!response?.length) throw new Error('No PLP layout found')

    const layout = response[0]

    const decodedBlocks: PhoenixBlock[] = layout.blocks.map((block) => {
        return {
            ...block,
            code: atob(block.code),
            // Transpiled code exists here as well but is not needed yet in the dashboard as it's transpiled on the fly in the editor.
            // We can add it if needed
            // transpiledCode: atob(block.transpiledCode),
        }
    })

    return { ...layout, blocks: decodedBlocks }
})

export const fetchLayoutById = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout | undefined,
    // First argument to the payload creator
    string,
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/fetchLayoutById', async (layoutId, { getState }) => {
    // Check if the layout is already in the fetched layouts
    const { phoenixLayouts } = getState()

    // If the current selected layout is the same as the one we are fetching, return it
    if (phoenixLayouts.currentSelectedLayout?._id === layoutId) {
        return phoenixLayouts.currentSelectedLayout
    }

    // If the layout is in the fetched layouts, return it
    const cachedLayout = phoenixLayouts.fetchedLayouts.find(
        (layout) => layout._id === layoutId
    )

    if (cachedLayout) {
        // No need to decode blocks, cached layout should already have decoded blocks
        return cachedLayout
    }

    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<FetchLayoutResponse>(
        `${phoenixAPI}/layouts/${appId}/${layoutId}`,
        {
            method: 'GET',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        }
    )

    const layout = response

    const decodedBlocks: PhoenixBlock[] = layout.blocks.map((block) => {
        return {
            ...block,
            code: atob(block.code),
            // Transpiled code exists here as well but is not needed yet in the dashboard as it's transpiled on the fly in the editor.
            // We can add it if needed
            // transpiledCode: atob(block.transpiledCode),
        }
    })

    return { ...layout, blocks: decodedBlocks }
})

export const fetchLayoutsByType = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout[],
    // First argument to the payload creator
    {
        type: LayoutType
        limit?: number
        sort?: 'asc' | 'desc'
        readOnly?: boolean
    },
    // Types for ThunkAPI
    { state: RootState }
>(
    'phoenix/fetchLayoutsByType',
    async (
        { type, limit = '10', sort = 'desc', readOnly = true },
        { getState }
    ) => {
        const { app } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id

        const headers = await getHeaders()

        const response = await typedRequest<FetchLayoutsResponse>(
            `${phoenixAPI}/layouts/${appId}?type=${type}&limit=${limit}&sort=${sort}&readonly=${readOnly}`,
            {
                method: 'GET',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
            }
        )

        const layouts = response.map((layout) => {
            const decodedBlocks: PhoenixBlock[] = layout.blocks.map((block) => {
                return {
                    ...block,
                    code: atob(block.code),
                    // Transpiled code exists here as well but is not needed yet in the dashboard as it's transpiled on the fly in the editor.
                    // We can add it if needed
                    // transpiledCode: atob(block.transpiledCode),
                }
            })

            return { ...layout, blocks: decodedBlocks }
        })

        return layouts
    }
)

export const savePhoenixBlock = createAsyncThunk<
    // Return type of the payload creator
    PhoenixBlock,
    // First argument to the payload creator
    { layoutId: string; block: BlockUpdatePayload },
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/saveBlock', async ({ layoutId, block }, { getState }) => {
    const blockId = block._id

    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<PhoenixBlock>(
        `${phoenixAPI}/layouts/${appId}/${layoutId}/blocks/${blockId}`,
        {
            method: 'PUT',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...block,
                _id: undefined,
                layoutId: undefined,
            }),
        }
    )

    response.code = atob(response.code)

    return response
})

export const revertPhoenixBlock = createAsyncThunk<
    // Return type of the payload creator
    PhoenixBlock,
    // First argument to the payload creator
    { layoutId: string; blockId: string },
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/revertBlock', async ({ layoutId, blockId }, { getState }) => {
    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<PhoenixBlock>(
        `${phoenixAPI}/layouts/${appId}/${layoutId}/blocks/${blockId}/revert`,
        {
            method: 'POST',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        }
    )

    response.code = atob(response.code)

    return response
})

export const replaceBlockInLayout = createAsyncThunk<
    // Return type of the payload creator
    { layout: PhoenixLayout; newBlockId: string },
    // First argument to the payload creator
    {
        layoutId: string
        block: BlockUpdatePayload
    },
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/replaceBlockInLayout', async ({ layoutId, block }, { getState }) => {
    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<{
        layout: PhoenixLayout
        newBlockId: string
    }>(
        `${phoenixAPI}/layouts/${appId}/${layoutId}/blocks/${block._id}/replace`,
        {
            method: 'POST',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...block,
                _id: undefined,
                layoutId: undefined,
            }),
        }
    )

    const decodedBlocks: PhoenixBlock[] = response.layout.blocks.map(
        (block) => {
            return {
                ...block,
                code: atob(block.code),
            }
        }
    )

    return {
        ...response,
        layout: { ...response.layout, blocks: decodedBlocks },
    }
})

export const cloneBlockTemplateToLayout = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout,
    // First argument to the payload creator
    {
        layoutId: string
        templateId: string
        position: number
    },
    // Types for ThunkAPI
    { state: RootState }
>(
    'phoenix/cloneBlockTemplateToLayout',
    async ({ layoutId, templateId, position }, { getState }) => {
        const { app } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id

        const headers = await getHeaders()

        const response = await typedRequest<PhoenixLayout>(
            `${appStudioAPI}/layouts/${appId}/${layoutId}/blocks`,
            {
                method: 'POST',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    templateId,
                    position,
                }),
            }
        )

        const decodedBlocks: PhoenixBlock[] = response.blocks.map((block) => {
            return {
                ...block,
                code: atob(block.code),
            }
        })

        return {
            ...response,
            blocks: decodedBlocks,
        }
    }
)

export const updateLayoutBlockList = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout,
    // First argument to the payload creator
    {
        layoutId: string
        blockIds: string[]
    },
    // Types for ThunkAPI
    { state: RootState }
>(
    'phoenix/updateLayoutBlockList',
    async ({ layoutId, blockIds }, { getState }) => {
        const { app } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id

        const headers = await getHeaders()

        const response = await typedRequest<PhoenixLayout>(
            `${appStudioAPI}/layouts/${appId}/${layoutId}/blocks`,
            {
                method: 'PUT',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    blockIds,
                }),
            }
        )

        const decodedBlocks: PhoenixBlock[] = response.blocks.map((block) => {
            return {
                ...block,
                code: atob(block.code),
            }
        })

        return {
            ...response,
            blocks: decodedBlocks,
        }
    }
)

export const updateDraftLayoutSettingsConfig = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout,
    // First argument to the payload creator
    {
        layoutId: string
        settingsConfig: Partial<PhoenixLayout['settingsConfig']>
    },
    // Types for ThunkAPI
    { state: RootState }
>(
    'phoenix/updateDraftLayout',
    async ({ layoutId, settingsConfig }, { getState }) => {
        const {
            app,
            phoenixLayouts: {
                currentSelectedLayout: {
                    // @ts-expect-error - We know this is not null at this point
                    settingsConfig: currentSettingsConfig,
                },
            },
        } = getState()
        const appData = app.data as { id: string }
        const appId = appData.id
        const headers = await getHeaders()

        const response = await typedRequest<{
            status: number
            updatedLayout: PhoenixLayout
        }>(`${phoenixAPI}/layouts/${appId}/${layoutId}`, {
            method: 'PUT',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...currentSettingsConfig,
                ...settingsConfig,
            }),
        })

        return {
            ...response.updatedLayout,
            settingsConfig: {
                ...response.updatedLayout.settingsConfig,
                ...settingsConfig,
            },
        }
    }
)

export const publishLayout = createAsyncThunk<
    // Return type of the payload creator
    { newDraftLayout: PhoenixLayout; publishedLayout: PhoenixLayout },
    // First argument to the payload creator
    string,
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/publishLayout', async (layoutId, { getState }) => {
    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<{
        newDraftLayout: PhoenixLayout
        publishedLayout: PhoenixLayout
    }>(`${phoenixAPI}/layouts/${appId}/${layoutId}/publish`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
        },
    })

    const { newDraftLayout, publishedLayout } = response

    const decodedDraftBlocks: PhoenixBlock[] = newDraftLayout.blocks.map(
        (block) => {
            return {
                ...block,
                code: atob(block.code),
            }
        }
    )

    const decodedPublishedBlocks: PhoenixBlock[] = publishedLayout.blocks.map(
        (block) => {
            return {
                ...block,
                code: atob(block.code),
            }
        }
    )

    return {
        newDraftLayout: {
            ...newDraftLayout,
            blocks: decodedDraftBlocks,
        },
        publishedLayout: {
            ...publishedLayout,
            blocks: decodedPublishedBlocks,
        },
    }
})

// Might be a better way to accomplish this but found this to be the most reliable way to reset the current selected layout.
// Only called when the user navigates away from app studio, actual logic is handled in the reducer.
export const resetCurrentSelectedLayout = createAsyncThunk(
    'phoenix/resetCurrentLayout',
    async () => {}
)

const phoenixLayoutsSlice = createSlice({
    name: 'phoenix-layouts',
    initialState,
    reducers: {
        cloneBlockTemplateToLayoutOptimistically: (
            state,
            action: PayloadAction<{
                layoutId: string
                block: PhoenixBlockTemplate
                position: number
            }>
        ) => {
            const { layoutId, block, position } = action.payload
            const layout = state.currentSelectedLayout

            if (layout && layout._id === layoutId) {
                // @ts-expect-error - We are optimistically adding a block to the layout, the block is not a PhoenixBlock yet
                layout.blocks.splice(position, 0, block)
            }
        },
        updateLayoutBlockListOptimistically: (
            state,
            action: PayloadAction<{
                layoutId: string
                blockIds: string[]
            }>
        ) => {
            const { layoutId, blockIds } = action.payload
            const layout = state.currentSelectedLayout

            if (layout && layout._id === layoutId) {
                const newBlockList: PhoenixBlock[] = []

                for (const blockId of blockIds) {
                    const blockIndex = layout.blocks.findIndex(
                        (block) => block._id === blockId
                    )
                    if (blockIndex === -1) {
                        return
                    }

                    const block = layout.blocks[blockIndex]
                    newBlockList.push(block)
                }

                layout.blocks = newBlockList
            }
        },
        updateDraftLayoutOptimistically: (
            state,
            action: PayloadAction<{
                layoutId: string
                settingsConfig: Partial<PhoenixLayout['settingsConfig']>
            }>
        ) => {
            const { layoutId, settingsConfig } = action.payload
            const layout = state.currentSelectedLayout

            if (layout && layout._id === layoutId) {
                layout.settingsConfig = {
                    ...layout.settingsConfig,
                    ...settingsConfig,
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDraftLayoutByType.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            fetchDraftLayoutByType.fulfilled,
            (state, { payload }) => {
                state.loading = 'fulfilled'

                if (payload) {
                    addLayoutToList(state, payload)
                    state.currentSelectedLayout = payload
                }

                state.initialized = true
            }
        )
        builder.addCase(fetchDraftLayoutByType.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
            state.initialized = true
        })
        builder.addCase(fetchLayoutById.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchLayoutById.fulfilled, (state, { payload }) => {
            state.loading = 'fulfilled'

            if (payload) {
                addLayoutToList(state, payload)
                state.currentSelectedLayout = payload
            }

            state.initialized = true
        })
        builder.addCase(fetchLayoutById.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(fetchLayoutsByType.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchLayoutsByType.fulfilled, (state, { payload }) => {
            state.loading = 'fulfilled'

            payload.forEach((layout) => {
                addLayoutToList(state, layout)
            })
            state.initialized = true
        })
        builder.addCase(fetchLayoutsByType.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
            state.initialized = true
        })
        builder.addCase(savePhoenixBlock.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(savePhoenixBlock.fulfilled, (state, { payload }) => {
            state.loading = 'fulfilled'

            const layout = state.currentSelectedLayout!

            const blockIndex = layout.blocks.findIndex(
                (block) => block._id === payload._id
            )
            layout.blocks[blockIndex] = payload
        })
        builder.addCase(savePhoenixBlock.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(revertPhoenixBlock.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(revertPhoenixBlock.fulfilled, (state, { payload }) => {
            state.loading = 'fulfilled'
            const layout = state.currentSelectedLayout!

            const blockIndex = layout.blocks.findIndex(
                (block) => block._id === payload._id
            )
            layout.blocks[blockIndex] = payload
        })
        builder.addCase(revertPhoenixBlock.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(replaceBlockInLayout.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            replaceBlockInLayout.fulfilled,
            (state, { payload }) => {
                state.loading = 'fulfilled'
                state.currentSelectedLayout = payload.layout
                addLayoutToList(state, payload.layout)
            }
        )
        builder.addCase(replaceBlockInLayout.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(publishLayout.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(publishLayout.fulfilled, (state, { payload }) => {
            state.loading = 'fulfilled'
            state.currentSelectedLayout = payload.newDraftLayout
            addLayoutToList(state, payload.newDraftLayout)
            addLayoutToList(state, payload.publishedLayout)
        })
        builder.addCase(publishLayout.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(updateDraftLayoutSettingsConfig.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            updateDraftLayoutSettingsConfig.fulfilled,
            (state, { payload }) => {
                state.loading = 'fulfilled'
                state.currentSelectedLayout!.settingsConfig = {
                    ...state.currentSelectedLayout!.settingsConfig,
                    ...payload.settingsConfig,
                }
            }
        )
        builder.addCase(
            updateDraftLayoutSettingsConfig.rejected,
            (state, action) => {
                state.loading = 'rejected'
                state.error = action.error.message
            }
        )
        builder.addCase(cloneBlockTemplateToLayout.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            cloneBlockTemplateToLayout.fulfilled,
            (state, { payload }) => {
                state.loading = 'fulfilled'
                state.currentSelectedLayout = payload
                addLayoutToList(state, payload)
            }
        )
        builder.addCase(
            cloneBlockTemplateToLayout.rejected,
            (state, action) => {
                state.loading = 'rejected'
                state.error = action.error.message
            }
        )
        builder.addCase(updateLayoutBlockList.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            updateLayoutBlockList.fulfilled,
            (state, { payload }) => {
                state.loading = 'fulfilled'
                state.currentSelectedLayout = payload
                addLayoutToList(state, payload)
            }
        )
        builder.addCase(updateLayoutBlockList.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(resetCurrentSelectedLayout.pending, (state) => {
            state.currentSelectedLayout = null
        })
    },
})

const addLayoutToList = (state: PhoenixLayoutState, layout: PhoenixLayout) => {
    // Remove the layout if it already exists
    state.fetchedLayouts = state.fetchedLayouts.filter(
        (l) => l._id !== layout._id
    )

    // Add it to state
    state.fetchedLayouts.push(layout)
}

export const draftLayoutHasChanges = (state: RootState): boolean => {
    if (!state.phoenixLayouts.currentSelectedLayout) return false

    const previouslyPublishedLayout = selectLastPublishedLayout(
        state.phoenixLayouts.currentSelectedLayout.type,
        state
    )

    if (!previouslyPublishedLayout) return true

    const currentDraftLayout = state.phoenixLayouts.currentSelectedLayout

    // Compare block ordering
    if (
        !_.isEqual(
            previouslyPublishedLayout.blocks.map((block) => block._id),
            currentDraftLayout.blocks.map((block) => block._id)
        )
    )
        return true

    // Compare settings configs
    if (
        !_.isEqual(
            previouslyPublishedLayout.settingsConfig,
            currentDraftLayout.settingsConfig
        )
    )
        return true

    // Check each block, if the code is different or there are manifest config or options changes, return true
    return currentDraftLayout.blocks.some((block) => {
        const previousBlock = previouslyPublishedLayout.blocks.find(
            (prevBlock) => prevBlock._id === block._id
        )

        // Once published, blocks become read only. If an edit was made, then it would create a new block
        // Meaning there are changes that are unpublished
        if (!previousBlock) return true

        // Quick check is if the block uses the template or not
        // Longer check is if the code or manifest config have changed
        return (
            block.useBlockTemplate !== previousBlock.useBlockTemplate ||
            block.code.length !== previousBlock.code.length ||
            block.code !== previousBlock.code ||
            !_.isEqual(block.manifestConfig, previousBlock.manifestConfig)
        )
    })
}

// There should always be a previously published layout
export const selectLastPublishedLayout = (
    type: LayoutType,
    state: RootState
): PhoenixLayout | null => {
    if (!state.phoenixLayouts.fetchedLayouts.length) return null

    // Find all layouts of the type and read only
    const layouts = state.phoenixLayouts.fetchedLayouts.filter(
        (layout) => layout.type === type && layout.readOnly
    )
    if (!layouts.length) return null

    // Find the latest layout
    const latestLayout = layouts.reduce((prev, current) =>
        prev.updatedAt > current.updatedAt ? prev : current
    )
    return latestLayout
}

export const selectCurrentSelectedLayout = (
    state: RootState
): PhoenixLayout | null => {
    return state.phoenixLayouts.currentSelectedLayout
}

export default phoenixLayoutsSlice.reducer

export const {
    updateDraftLayoutOptimistically,
    cloneBlockTemplateToLayoutOptimistically,
    updateLayoutBlockListOptimistically,
} = phoenixLayoutsSlice.actions
